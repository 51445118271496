import React, {Component} from 'react';

import safe_get from '../../other/SafeGet.js';
import {get_corrective_actions, update_corrective_action} from '../../api/zero-api.js'

import {Modal} from 'antd';

import NewPostModal from '../Bulletin/NewPostModal'
import LoadingIndicator from '../Shared/LoadingIndicator.js';
import NotificationAlert from '../../other/NotificationAlert';

import '../../assets/css/antd-custom.css';

class CorrectiveActionsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            corrective_actions: [],
            hideCreatePostIndexes: [],
        };

    }

    componentDidMount() {
        this.getcorrectActions();
    }

    getcorrectActions = () => {
        var self = this;
        get_corrective_actions(this.props.incident_uuid).then(function (success) {
            success.json().then(success => {
                var actions = safe_get(success, "corrective_actions", []);
                actions = actions.filter(function (obj) {
                    return obj.text !== ""
                });
                self.setState({
                    corrective_actions: actions,
                    showNewPost: false,
                    loading: false
                });
            });
        }, function (error) {
            self.setState({
                loading: false
            });
        });
    }

    postCreated = (post) => {
        var self = this;

        var actions = [...this.state.corrective_actions];
        var action = this.state.selected_action;

        var index = actions.indexOf(action);

        var body = JSON.stringify({
            text: action.text,
            post_uuid: post.post_uuid.replace("offline:", ""),
        });

        update_corrective_action(this.props.incident_uuid, action.corrective_action_uuid, body).then(function (success) {
            success.json().then(success => {
                var updated_action = safe_get(success, "corrective_action", {});
                actions[index] = updated_action;
                self.setState({corrective_actions: actions, showNewPost: false});
                NotificationAlert("success", "", "Post created.");
            });
        }, function (error) {
            self.setState({showNewPost: false});
            NotificationAlert("error", "", "Unable to create post.");
        });

    }

    render() {
        if (this.state.showNewPost) {
            return (
                <NewPostModal
                    show={this.state.showNewPost}
                    cancel={() => {
                        this.setState({showNewPost: false});
                    }}
                    description={this.state.selected_action.text}
                    postCreated={this.postCreated}
                />
            )
        }
        return (
            (<Modal
                title="Actions and Notes"
                open={this.props.show}
                maskClosable={false}
                onCancel={this.props.cancel}
                maskTransitionName="maskTransitionName"
                footer={
                    <div style={{height: "33px"}}>
                        <button className="btn btn-modal" onClick={this.props.cancel}>
                            Done
                        </button>
                    </div>
                }
            >
                <div style={{maxHeight: "450px", overflowY: "auto"}}>
                    {
                        this.state.loading &&
                        <LoadingIndicator/>
                    }
                    {
                        !this.state.loading &&
                        this.state.corrective_actions.map((action, index) => (
                            <div className="dont-print" key={action.corrective_action_uuid}>
                                <div className="flex-col flex-row-sm" style={{justifyContent: 'space-between'}}>
                                    <h3 className="titles onboarding invite-link mt-0"
                                        style={{margin: "0px", fontSize: "100%", lineHeight: "1.4"}}>
                                        {index + 1}. {action.text}
                                    </h3>
                                    <div>
                                        {
                                            action.related_post &&
                                            <>
                                                <span className="btn-link link-hover" onClick={() => {
                                                    this.props.goToPost(action.related_post.post_uuid)
                                                }}>Post created</span>
                                                <span>&nbsp;({safe_get(action, "related_post.status", "N/A")})</span>
                                            </>
                                        }
                                        {
                                            !action.related_post && (this.state.hideCreatePostIndexes.indexOf(index) < 0) &&
                                            <span className="link-hover zero-light-blue" onClick={() => {
                                                this.setState({selected_action: action, showNewPost: true})
                                            }}>Post</span>
                                        }

                                    </div>
                                </div>
                                {
                                    index < this.state.corrective_actions.length - 1 &&
                                    <hr/>
                                }
                            </div>
                        ))
                    }
                </div>
            </Modal>)
        );
    }

}

export default CorrectiveActionsModal;