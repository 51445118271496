import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';

import UserPopoverCard from '../../Shared/UserPopoverCard';
import RespondersPopover from '../../Shared/RespondersPopover';
import {
    safe_get,
    scrollToTop,
    mainContentContainerClass,
    hideFooterMobileApp,
    stringToBoolean,
    safeProfilePic,
    isAdmin
} from '../../../other/Helper.js';

import {
    deleteFormSubmissionAlert,
    getAllFormSubmissionAlerts,
    getFormSubmissionAlertOptions,
    getAllIncidentAlerts,
    deleteIncidentAlert,
    getIncidentAlertOptions,
    patchFormSubmissionAlert,
    patchIncidentAlert,
    newPostAlertsApi,
} from '../../../api/zero-api.js';

import Skeleton from 'react-loading-skeleton'

import {PlusCircleOutlined, EditOutlined, UserAddOutlined, DeleteOutlined} from '@ant-design/icons';
import {Menu, Dropdown, Select, Popover} from 'antd'

import {IoIosMore as MoreIcon} from 'react-icons/io';
import NotificationAlert from '../../../other/NotificationAlert.js';
import ConfirmModal from '../../Shared/ConfirmModal.js';
import NewEditAlertModal from './NewEditAlertModal.js';

class ManageFormAlerts extends Component {
    constructor(props) {
        super(props);

        this.state = {
            alerts: [],
            filteredAlerts: Array(9).fill(0),
            loadingAlerts: true,
            searchValue: [],
            formOptions: {
                forms: [],
                teams: [],
                users: [],
            },
            locationOptions: [],
            feedOptions: {
                categories: [],
                users: [],
                teams: [],
            },
            allUsers: [],
            activeTab: 'feed'
        };
    }

    componentDidUpdate(nextProps) {
        scrollToTop("page-head");
    }

    componentWillUnmount() {

    }

    componentDidMount() {
        scrollToTop("page-head");
        hideFooterMobileApp();
        this.getOptions();
        this.getAlerts();
    }

    getAlerts = (newTab = null) => {
        var self = this;

        if (newTab && this.state.activeTab !== newTab) {
            this.switchTab(newTab);
            return;
        }

        switch (newTab || this.state.activeTab) {
            case 'forms':
                getAllFormSubmissionAlerts().then(success => {
                    success.json().then(success => {
                        let alerts = success.sort(function (a, b) {
                            if (a.alert_type > b.alert_type) {
                                return 1;
                            } else if (a.form.name.toLowerCase() > b.form.name.toLowerCase()) {
                                return 1;
                            } else {
                                return -1;
                            }
                        });

                        self.setState({loadingAlerts: false, alerts: alerts, filteredAlerts: alerts});
                    });
                });
                break;

            case 'incidents':
                getAllIncidentAlerts().then(success => {
                    success.json().then(success => {
                        let alerts = success;
                        self.setState({loadingAlerts: false, alerts: alerts, filteredAlerts: alerts});
                    });
                });
                break;

            case 'feed':
                newPostAlertsApi
                    .list()
                    .then(res => res.json())
                    .then(alerts => {
                        this.setState({
                            loadingAlerts: false,
                            alerts,
                            filteredAlerts: alerts,
                        });
                    })
                break;
        }
    }

    getOptions = () => {
        getFormSubmissionAlertOptions()
            .then(res => res.json())
            .then(formOptions => {
                this.setState({formOptions})
            });

        getIncidentAlertOptions()
            .then(res => res.json())
            .then(data => {
                this.setState({
                    locationOptions: safe_get(data, 'locations', []),
                    allUsers: safe_get(data, 'users', [])
                });
            });

        newPostAlertsApi
            .options()
            .then(res => res.json())
            .then(feedOptions => {
                this.setState({
                    feedOptions
                });
            })
    }


    selectFilter = (value) => {
        if (value && value !== "") {
            var filterList = [...this.state.alerts];
            for (var i in value) {
                let filter = value[i];
                let searchValue = filter.toLowerCase();

                if (searchValue.includes("team:")) {
                    let team_uuid = searchValue.split(":")[1];
                    filterList = filterList.filter(function (schedule) {
                        let scheduleTeams = safe_get(schedule, "teams", []);
                        return scheduleTeams.find(function (team) {
                            return team.team_uuid === team_uuid
                        });
                    });
                } else if (searchValue.includes("form_uuid:")) {
                    let form_uuid = searchValue.split(":")[1];
                    filterList = filterList.filter(function (alert) {
                        return (alert.form.uuid === form_uuid);
                    });
                } else if (searchValue.includes("schedule_status:")) {
                    let status = stringToBoolean(searchValue.split(":")[1]);
                    filterList = filterList.filter(function (object) {
                        return (object.enabled === status);
                    });
                } else if (searchValue.includes("location_uuid:")) {
                    let location_uuid = searchValue.split(':')[1];
                    filterList = filterList.filter(function (alert) {
                        if (alert.all_locations) {
                            return true;
                        } else if (alert.locations) {
                            const uuids = alert.locations.map(location => location.location_uuid);
                            return uuids.includes(location_uuid);
                        }
                        return false;
                    });
                } else {
                    filterList = filterList.filter(function (alert) {

                        let subscribers = alert.subscribed_users.find(function (user) {
                            let name = user.full_name;
                            return name.toLowerCase().includes(searchValue)
                        });

                        return (
                            safe_get(alert, "form.name", "").toLowerCase().includes(searchValue) ||
                            safe_get(alert, "form.uuid", "").includes(filter) ||
                            subscribers !== undefined
                        );
                    });
                }

            }

            this.setState({filteredAlerts: filterList, searchValue: value});
        } else {
            this.setState({filteredAlerts: this.state.alerts, searchValue: undefined});
        }

    }

    deleteAlert = () => {
        var self = this;

        let deleteAlert = (uuid) => Promise.reject(new Error());
        let alertType = safe_get(this.state.editAlert, 'alert_type', 'unknown');

        switch (alertType) {
            case 'form_submission':
                deleteAlert = deleteFormSubmissionAlert;
                break;
            case 'new_incident':
                deleteAlert = deleteIncidentAlert;
                break;
            case 'new_post':
                deleteAlert = newPostAlertsApi.delete;
                break;
        }

        deleteAlert(this.state.editAlert.uuid).then(_success => {
            self.getAlerts();
            self.setState({showDeleteAlertModal: false, editAlert: undefined});
            NotificationAlert("success", "", "Alert deleted.");
        }, _error => {
            self.setState({showDeleteAlertModal: false, editAlert: undefined});
            NotificationAlert("error", "", "Unable to delete alert.");
        });
    }


    alertNameFormatter = (alert) => {
        let alert_type = safe_get(alert, "alert_type", "").replace(/_/g, ' ');
        return (
            <span className="zero-dark-grey" style={{fontWeight: "400", textTransform: "capitalize"}}>
                {alert_type} <span style={{fontSize: 15}}>&rarr;</span> {alert.notification_type} Alert
            </span>

        );
    }

    updateResponders = async (alert, responder_uuids) => {
        let patchFunction = null;
        if (alert.alert_type === 'form_submission') {
            patchFunction = patchFormSubmissionAlert;
        } else if (alert.alert_type === 'new_incident') {
            patchFunction = patchIncidentAlert;
        } else if (alert.alert_type === 'new_post') {
            patchFunction = newPostAlertsApi.partialUpdate;
        }

        try {
            const body = JSON.stringify({
                subscribed_user_uuids: responder_uuids
            });
            const response = await patchFunction(alert.uuid, body);
            this.getAlerts();
        } catch (err) {
            console.error(err);
            NotificationAlert('error', '', 'Unable to update subscribers for alert.');
        }
    }

    getAvailableRespondersFromFormAlert(alert) {
        const potentialUsers = this.state.formOptions.users;
        if (potentialUsers.length === 0) {
            return [];
        }

        const triggerTeams = safe_get(alert, 'trigger_teams', []);
        if (triggerTeams.length === 0) {
            return [];
        }

        const triggerTeamId = triggerTeams[0].uuid;
        return potentialUsers.filter(user => isAdmin(user) || user.team_uuids.includes(triggerTeamId));
    }

    getAvialableRespondersFromIncidentAlert(alert) {
        const potentialUsers = this.state.allUsers;
        if (potentialUsers.length === 0) {
            return [];
        }

        if (alert.all_locations) {
            return potentialUsers;
        }

        const locations = safe_get(alert, 'locations', []);
        if (locations.length === 0) {
            return [];
        }

        // array of arrays of user UUIDs
        const userIdsForLocations = locations.map(location => location.access_list.map(user => user.uuid));

        // build a set of all possible users for every location
        const userIdSet = new Set();
        for (const userIdsForLocation of userIdsForLocations) {
            for (const uuid of userIdsForLocation) {
                userIdSet.add(uuid);
            }
        }

        if (userIdSet.length == 0) {
            return [];
        }

        // convert set to array and filter out potential users
        const allowedUserIds = [...userIdSet];
        return potentialUsers.filter(user => allowedUserIds.includes(user.uuid));
    }

    getAvialableRespondersFromPostAlert(alert) {
        const potentialUsers = safe_get(this.state, 'feedOptions.users', []);
        if (potentialUsers.length === 0) {
            return [];
        }

        const triggerTeams = safe_get(alert, 'trigger_teams', []);
        if (triggerTeams.length === 0) {
            return [];
        }

        const triggerTeamId = triggerTeams[0].uuid;
        return potentialUsers.filter(user => isAdmin(user) || user.team_uuids.includes(triggerTeamId));
    }

    teamsFormatter = (alert) => {
        var teams = safe_get(alert, "trigger_teams", []);
        var team_names = [];

        teams.forEach(team => {
            if (team && team.name) {
                team_names.push(team.name)
            }
        });

        team_names.sort();

        let tableText = team_names[0];
        if (team_names.length === 2) {
            tableText = `${team_names[0]} and ${team_names[1]}`;
        } else if (team_names.length > 2) {
            tableText = `${team_names[0]} and ${team_names.length - 1} more...`;
        }

        return (
            <Popover content={team_names.join(", ")} placement="topLeft" overlayStyle={{maxWidth: "220px"}}>
                <span className="zero-dark-grey">
                    {tableText}
                </span>
            </Popover>
        )
    }

    templateFormatter = (alert) => {
        return (
            <span className="zero-dark-grey" style={{fontWeight: "400"}}>
                {safe_get(alert, "form.name", "")}
            </span>
        );
    }

    respondersFormatter = (alert) => {
        let responders = safe_get(alert, "subscribed_users", []).sort(function (a, b) {
            let a_name = a.first_name + " " + a.last_name;
            let b_name = b.first_name + " " + b.last_name
            return a_name.toLowerCase() > b_name.toLowerCase() ? 1 : -1
        });

        let availableResponders = [];
        switch (alert.alert_type) {
            case 'form_submission':
                availableResponders = this.getAvailableRespondersFromFormAlert(alert);
                break;
            case 'new_incident':
                availableResponders = this.getAvialableRespondersFromIncidentAlert(alert);
                break;
            case 'new_post':
                availableResponders = this.getAvialableRespondersFromPostAlert(alert);
                break;
        }

        if (alert.assigned_to_team) {
            return (
                <p className="zero-dark-grey text-center mar-btm-0" style={{fontWeight: "400"}}>All Team Members</p>
            )
        } else {
            return (
                <div style={{textAlign: "center"}}>
                    {
                        responders.slice(0, 3).map((user, index) => (
                            <UserPopoverCard
                                key={index}
                                user={user}
                                subscribers={responders}
                                showRemoveSubscriber={true}
                                updateSubscribers={responder_uuids => this.updateResponders(alert, responder_uuids)}
                            >
                                <span className="hover-cursor">
                                    {safeProfilePic(user, "img-sm img-circle bulletin", "bulletin", {
                                        marginRight: "5px",
                                        display: "inline-block"
                                    })}
                                </span>
                            </UserPopoverCard>
                        ))
                    }
                    {
                        responders.length > 3 &&
                        <RespondersPopover
                            title="Notifications"
                            available_responders={availableResponders}
                            selected_responders={responders}
                            updateResponders={responder_uuids => this.updateResponders(alert, responder_uuids)}
                        >
                            <span>
                                <div className={"default-avatar-circle bulletin"}
                                     style={{marginRight: "5px", display: "inline-block", border: "1px solid #505050"}}>
                                    <p className="initials" style={{color: "#505050"}}>+{responders.length - 3}</p>
                                </div> 
                            </span>
                        </RespondersPopover>
                    }
                    {responders.length <= 3 &&
                    <RespondersPopover
                            title="Notifications"
                            available_responders={availableResponders}
                            selected_responders={responders}
                            updateResponders={responder_uuids => this.updateResponders(alert, responder_uuids)}
                        >
                            <button className="ButtonLink post-details-content mar-top-5"
                                    style={{verticalAlign: "bottom", height: "26px", width: "26px"}}>
                                <UserAddOutlined style={{fontSize: "19px"}}/>
                            </button>
                        </RespondersPopover>
                    }
                </div>
            )
        }

    }

    locationsFormatter = (alert) => {
        const all_locations = safe_get(alert, 'all_locations', false);
        const locations = safe_get(alert, 'locations', []);
        const location_names = [];

        if (all_locations) {
            return (
                <Popover content={"All Locations"} placement="topLeft" overlayStyle={{maxWidth: "220px"}}>
                    <span className="zero-dark-grey">All Locations</span>
                </Popover>
            )
        } else {
            locations.forEach(location => {
                if (location && location.name) {
                    location_names.push(location.name);
                }
            })

            location_names.sort();

            return (
                <Popover content={location_names.join(', ')} placement="topLeft" overlayStyle={{maxWidth: "220px"}}>
                    <span className="zero-dark-grey">
                        {location_names.join(', ')}
                    </span>
                </Popover>
            )
        }
    }

    optionsFormatter = (alert) => {
        const menu = {
            onClick: ({key}) => this.handleMenuClick(key, alert),
            items: [
                {
                    key: 'edit',
                    icon: <EditOutlined/>,
                    label: 'Edit Alert',
                },
                {
                    type: 'divider',
                },
                {
                    key: 'delete',
                    label: 'Delete Alert',
                    icon: <DeleteOutlined/>,
                    className: 'zero-delete-red',
                },
            ]
        }

        return (
            <div className="media-right more" style={{marginTop: '6px', color: "grey", lineHeight: 0, height: 'fit-content'}}>
                {
                    <Dropdown menu={menu} trigger={['click']}>
                        <button className="ButtonLink ant-dropdown-link">
                            <MoreIcon style={{height: '20px', width: '20px'}}/>
                        </button>
                    </Dropdown>
                }
            </div>
        );
    }

    handleMenuClick = (key, alert) => {
        if (key === "edit") {
            this.setState({editAlert: alert, showNewEditAlertModal: true});
        } else if (key === "delete") {
            this.setState({editAlert: alert, showDeleteAlertModal: true});
        }
    }

    switchTab(name) {
        this.setState({
            activeTab: name,
            searchValue: [],
            loadingAlerts: true,
            alerts: []
        }, this.getAlerts)
    }

    renderTableHeader() {
        return (
            <thead style={{color: "#1D2A35"}}>
            {
                this.state.activeTab == 'forms' &&
                <tr>
                    <th></th>
                    <th className="zero-blue" style={{width: "250px"}}>
                        {this.state.loadingAlerts ? <Skeleton width={100}/> : "Type"}
                    </th>
                    <th className="zero-blue" style={{width: "210px"}}>
                        {this.state.loadingAlerts ? <Skeleton width={130}/> : "Template"}
                    </th>
                    <th className="zero-blue text-center">
                        {this.state.loadingAlerts ? <Skeleton width={50}/> : "Team"}
                    </th>
                    <th className="zero-blue" style={{width: "210px", textAlign: "center"}}>
                        {this.state.loadingAlerts ? <Skeleton width={130}/> : "Notifying"}
                    </th>
                </tr>
            }
            {
                this.state.activeTab == 'incidents' &&
                <tr>
                    <th style={{width: "30px"}}></th>
                    <th className="zero-blue" style={{width: "250px"}}>
                        {this.state.loadingAlerts ? <Skeleton width={100}/> : "Type"}
                    </th>
                    <th className="zero-blue text-center">
                        {this.state.loadingAlerts ? <Skeleton width={50}/> : "Location"}
                    </th>
                    <th className="zero-blue" style={{width: "210px", textAlign: "center"}}>
                        {this.state.loadingAlerts ? <Skeleton width={130}/> : "Notifying"}
                    </th>
                </tr>
            }
            {
                this.state.activeTab === 'feed' &&
                <tr>
                    <th style={{width: "30px"}}></th>
                    <th className="zero-blue" style={{width: "250px"}}>
                        {this.state.loadingAlerts ? <Skeleton width={100}/> : "Type"}
                    </th>
                    <th className="zero-blue text-center">
                        {this.state.loadingAlerts ? <Skeleton width={50}/> : "Category"}
                    </th>
                    <th className="zero-blue text-center">
                        {this.state.loadingAlerts ? <Skeleton width={50}/> : "Team"}
                    </th>
                    <th className="zero-blue" style={{width: "210px", textAlign: "center"}}>
                        {this.state.loadingAlerts ? <Skeleton width={130}/> : "Notifying"}
                    </th>
                </tr>
            }
            </thead>
        )
    }

    renderTableBody() {
        if (this.state.loadingAlerts) {
            return (
                <tbody>
                {
                    [...Array(10)].map((_item, index) => (
                        <tr key={index} className="tr-hover">
                            <td><Skeleton width={25}/></td>
                            <td style={{width: "250px"}}><Skeleton width={150}/></td>
                            {
                                this.state.activeTab == 'forms' &&
                                <td style={{width: "210px"}}><Skeleton width={130}/></td>
                            }
                            <td className="text-center"><Skeleton width={50}/></td>
                            <td style={{width: "210px", textAlign: "center"}}>
                                {
                                    [...Array(4)].map((e, index) => (
                                        <span key={index} style={{marginRight: "5px", marginTop: "5px"}}>
                                            <Skeleton key={index} circle={true} width={26} height={26} inline />
                                        </span>
                                    ))
                                }
                            </td>
                        </tr>
                    ))
                }
                </tbody>
            )
        } else if (this.state.filteredAlerts.length > 0) {
            switch (this.state.activeTab) {
                case 'forms':
                    return (
                        <tbody>
                        {
                            this.state.filteredAlerts.map((alert, index) => (
                                <tr key={index} className="tr-hover">
                                    <td>{this.optionsFormatter(alert)}</td>
                                    <td style={{width: "250px"}}>{this.alertNameFormatter(alert)}</td>
                                    <td style={{width: "210px"}}>{this.templateFormatter(alert)}</td>
                                    <td className="text-center">{this.teamsFormatter(alert)}</td>
                                    <td style={{width: "210px"}}>{this.respondersFormatter(alert)}</td>
                                </tr>
                            ))
                        }
                        </tbody>
                    )
                case 'incidents':
                    return (
                        <tbody>
                        {
                            this.state.filteredAlerts.map((alert, index) => (
                                <tr key={index} className="tr-hover">
                                    <td>{this.optionsFormatter(alert)}</td>
                                    <td style={{width: "250px"}}>{this.alertNameFormatter(alert)}</td>
                                    <td className="text-center">{this.locationsFormatter(alert)}</td>
                                    <td style={{width: "210px"}}>{this.respondersFormatter(alert)}</td>
                                </tr>
                            ))
                        }
                        </tbody>
                    )
                case 'feed':
                    return (
                        <tbody>
                        {
                            this.state.filteredAlerts.map((alert, index) => (
                                <tr key={index} className="tr-hover">
                                    <td>{this.optionsFormatter(alert)}</td>
                                    <td style={{width: "250px"}}>{this.alertNameFormatter(alert)}</td>
                                    <td className="text-center zero-dark-grey">{alert.category.name}</td>
                                    <td className="text-center zero-dark-grey">{this.teamsFormatter(alert)}</td>
                                    <td style={{width: "210px"}}>{this.respondersFormatter(alert)}</td>
                                </tr>
                            ))
                        }
                        </tbody>
                    )
            }

        } else {
            return (
                <tbody>
                <tr>
                    <td className="react-bs-table-no-data zero-dark-grey" colSpan="6">
                        No alerts.
                    </td>
                </tr>
                </tbody>
            )
        }
    }

    render() {
        return (
            <div className={mainContentContainerClass()}>

                {
                    this.state.showNewEditAlertModal &&
                    <NewEditAlertModal
                        show={this.state.showNewEditAlertModal}
                        cancel={() => {
                            this.setState({showNewEditAlertModal: false, editAlert: undefined});
                        }}
                        alert={this.state.editAlert}
                        formOptions={this.state.formOptions}
                        locationOptions={this.state.locationOptions}
                        feedOptions={this.state.feedOptions}
                        user={this.props.user}
                        allUsers={this.state.allUsers}
                        updateAlerts={this.getAlerts}
                    />
                }
                {
                    this.state.showDeleteAlertModal &&
                    <ConfirmModal
                        show={this.state.showDeleteAlertModal}
                        cancel={() => {
                            this.setState({showDeleteAlertModal: false, editAlert: undefined});
                        }}
                        title={"Confirmation"}
                        body={"Are you sure you want to delete this alert?"}
                        confirmButtonName={"Delete alert"}
                        confirm={this.deleteAlert}
                    />
                }

                <div id="page-head" className="no-padding-mobile">
                    <div className="row">
                        <div className="col-lg-12  col-xs-12 col-xs-offset-0">
                            <div id="page-title" style={{padding: '0px'}}>
                                <div className="panel thin-border" style={{maxWidth: "850px", margin: "0 auto"}}>
                                    <div className="nav-header-panel no-padding-print">
                                        <div className="team-name-header">
                                            <h3 className={"section-titles admin-page-header"}
                                                style={{display: "inline-block", margin: "0px"}}>
                                                Manage Alerts
                                            </h3>
                                            <button className="btn btn-primary pull-right hidden-xs" style={{
                                                width: '145px',
                                                textAlign: 'center',
                                                display: 'inline-block',
                                                padding: "6px"
                                            }} onClick={() => {
                                                this.setState({showNewEditAlertModal: true});
                                            }}>
                                                <PlusCircleOutlined/> Create Alert
                                            </button>
                                            <button className="btn btn-primary pull-right visible-xs" style={{
                                                width: '50px',
                                                textAlign: 'center',
                                                display: 'inline-block',
                                                padding: "6px"
                                            }} onClick={() => {
                                                this.setState({showNewEditAlertModal: true});
                                            }}>
                                                <PlusCircleOutlined/>
                                            </button>
                                        </div>
                                        <p className="header"
                                           style={{color: "#505050", marginBottom: "0px", paddingLeft: "2px"}}>
                                            Create and edit alerts. Email alerts can be configured for newly created posts,
                                            form submissions, or incidents.
                                        </p>

                                        <div className="members-search" style={{width: "100%", marginTop: "8px"}}>
                                            <Select
                                                mode="tags"
                                                placeholder={"Filter or search..."}
                                                style={{width: "100%"}}
                                                // filterOption={false}
                                                // onSearch={this.searchSchedule}
                                                onChange={this.selectFilter}
                                                value={this.state.searchValue}
                                                allowClear={true}
                                                virtual={false}
                                                // showSearch={true}
                                            >
                                                {
                                                    this.state.activeTab === 'forms' &&
                                                    <Select.OptGroup label={"Template Name"}>
                                                        {
                                                            this.state.formOptions.forms.map((form, index) => (
                                                                <Select.Option key={index}
                                                                               value={"form_uuid:" + form.uuid}>{form.name}</Select.Option>
                                                            ))
                                                        }
                                                    </Select.OptGroup>
                                                }
                                                {
                                                    this.state.activeTab === 'incidents' &&
                                                    <Select.OptGroup label={"Location Name"}>
                                                        {
                                                            this.state.locationOptions.map((location, index) => (
                                                                <Select.Option key={index}
                                                                               value={"location_uuid:" + location.uuid}>{location.name}</Select.Option>
                                                            ))
                                                        }
                                                    </Select.OptGroup>
                                                }
                                            </Select>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="page-content" className="no-padding-mobile">
                    <div className="row" style={{maxWidth: "850px", margin: "0 auto"}}>
                        <div className="tab-base" style={{marginBottom: "0px", padding: "0px"}}>
                            <ul className="nav nav-tabs">
                                <li className={this.state.activeTab == "feed" ? "active" : ""}>
                                    <button className="ButtonLink" onClick={() => this.switchTab('feed')}>Posts</button>
                                </li>
                                <li className={this.state.activeTab == "forms" ? "active" : ""}>
                                    <button className="ButtonLink" onClick={() => this.switchTab('forms')}>Forms
                                    </button>
                                </li>
                                <li className={this.state.activeTab == "incidents" ? "active" : ""}>
                                    <button className="ButtonLink"
                                            onClick={() => this.switchTab('incidents')}>Incidents
                                    </button>
                                </li>
                            </ul>
                            {/*<div className="panel mar-btm-0">*/}
                            <div className="tab-content" style={{border: "1px solid #e2e2e2", padding: "0px 0px"}}>
                                <div className="table-responsive">
                                    <table className="table table-vcenter" style={{marginBottom: "0px"}}>
                                        {this.renderTableHeader()}
                                        {this.renderTableBody()}
                                    </table>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>

        );
    }


}


const mapStateToProps = store => {
    return {
        user: safe_get(store, "user.user", []),
        org_uuid: safe_get(store, "org_helper.organization.organization_uuid", ""),
        teams: safe_get(store, "teams_helper.teams", [])
    }
}

export default withRouter(connect(mapStateToProps)(ManageFormAlerts));
