// @ts-check

import ResetGridLink from "components/Shared/DataGrid/ResetGridLink";
import { EventNames } from "./IncidentDataGrid.utils";
import { useIncidentDataGridContext } from "./IncidentDataGrid.context";

export default function ResetIncidentGridLink() {
    const { settings } = useIncidentDataGridContext();

    return (
        <ResetGridLink
            settings={settings}
            resetTableEventName={EventNames.RESET_TABLE}
            defaultSortModel={null}            
        />
    );
}
