// @ts-check
import { createContext, useContext, useMemo, useState, useRef, useEffect } from "react";
import { get_custom_fields, get_incident_options } from "api/zero-api";
import { useDataGridSettings } from "components/Bulletin/FeedDataGrid/FeedDataGrid.hooks";

/**
 * @typedef {defaultIncidentOptions} IncidentOptions
 */

const IncidentDataGridContext = createContext(null);

/** @returns {ReturnType<useContextValue>} */
export function useIncidentDataGridContext() {
    return useContext(IncidentDataGridContext);
}

export function IncidentDataGridContextProvider({children}) {
    const ctxValue = useContextValue();
    
    return (
        <IncidentDataGridContext.Provider value={ctxValue}>
            {children}
        </IncidentDataGridContext.Provider>
    )
}

const defaultIncidentOptions = {
    body_front_parts: [],
    body_back_parts: [],
    case_classifications: [],
    incident_types: [],
    incident_causes: [],
    event_types: [],
    incident_illnesses: [],
    incident_injuries: [],
    sex: [],
};

function useContextValue() {
    const [settings, setSettings] = useDataGridSettings('incidents');
    const [rowCount, setRowCount] = useState(null);
    const [activeModal, setActiveModal] = useState(null);
    const [incidentOptions, setIncidentOptions] = useState(defaultIncidentOptions);
    const [customFields, setCustomFields] = useState([]);
    /** @type {import("react").MutableRefObject<AgGridReactLib.AgGridReact>} */
    const gridRef = useRef();

    useEffect(() => {
        const loadOptions = async () => {
            try {
                const res = await get_incident_options();
                const { data } = await res.json();
                setIncidentOptions(data);
            } catch (err) {
                console.error("Could not load incident options");
            }
        };

        loadOptions();
    }, []);

    useEffect(() => {
        const loadCustomFields = async () => {
            try {
                const res = await get_custom_fields();
                const customFields = await res.json();
                setCustomFields(customFields);
            } catch (err) {
                console.error("Could not load incident custom fields");
            }
        };

        loadCustomFields();
    }, []);

    return useMemo(() => ({
        settings,
        setSettings,
        rowCount,
        setRowCount,
        activeModal,
        setActiveModal,
        incidentOptions,
        customFields,
        gridRef,
    }), [settings, setSettings, rowCount, setRowCount, activeModal, setActiveModal, incidentOptions, customFields, gridRef]);
}