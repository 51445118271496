import React, {Component} from 'react';

import {get_submission, link_post_to_field} from '../../api/zero-api.js'
import {safe_get, isVideo, isImage, getFileThumbnail, shouldFormFieldDisplay} from '../../other/Helper'

import {Modal} from 'antd';

import LoadingIndicator from '../Shared/LoadingIndicator.js';

import '../../assets/css/antd-custom.css';

import FormFieldsRenderer from './FormFieldsRenderer'
import NotificationAlert from "../../other/NotificationAlert";

/**
 * 
 * @param {any[]} formFields 
 * @param {any[]} submissionFields 
 * @param {'comments' | 'attachments'} mode
 */
function getVisibleCommentFields(formFields, submissionFields, mode) {
    /**
     * @typedef {object} MetaField
     * @property {string} id
     * @property {boolean} [visible]
     * @property {object} question
     * @property {object} [answer]
     * @property {MetaField[]} children
     */

    /** @type {MetaField[]} */
    const metaFields = [];
    const orphans = [];
    const findAnswer = (id) => submissionFields.find(f => f.id === id);
    const findSection = (id) => metaFields.find(f => f.id === id);
    const addFieldToSection = (section, field) => section.children.push(field);
    const shouldFieldDisplay = (metaField, sectionMetaField = null) => shouldFormFieldDisplay(metaField.question, submissionFields, true, false, sectionMetaField?.question ?? null, false);


    // populate the meta-field array
    for (const formField of formFields) {
        /** @type {MetaField} */
        const newField = {
            id: formField.id,
            visible: undefined,
            question: formField,
            answer: findAnswer(formField.id),
            children: [],
        }

        if (formField.sectionId) {
            const section = findSection(formField.sectionId);
            if (section) {
                addFieldToSection(section, newField);
            } else {
                // unknown section, add to orphan list for now
                orphans.push(newField);
            }
        } else {
            metaFields.push(newField)
        }
    }

    // loop through each orphan and add it to its section if it exists
    // unknown sections are ignored
    for (const orphan of orphans) {
        const section = findSection(orphan.question.sectionId);
        if (section) {
            addFieldToSection(section, orphan);
        }
    }

    // loop through each meta-field and determine if it's visible
    for (const metaField of metaFields) {
        metaField.visible = shouldFieldDisplay(metaField);

        for (const childMetaField of metaField.children) {
            if (metaField.visible) {
                childMetaField.visible = shouldFieldDisplay(childMetaField, metaField);
            } else {
                childMetaField.visible = false;
            }
        }
    }

    const visibleComments = [];
    const visibleMetaFields = metaFields.filter(f => f.visible);
    const shouldRender = (metaField) => {
        const isAttachmentsField = metaField.question.element === "Attachments";
        if (isAttachmentsField) {
            return false;
        }
        const hasComment = (metaField.answer?.comment?.length ?? 0) > 0;
        const hasAttachments = (metaField.answer?.attachments?.length ?? 0) > 0;

        if (mode === 'comments') {
            return hasComment;
        } else if (mode === 'attachments') {
            return hasAttachments;
        } else {
            return false;
        }
    };
    
    // get visible fields with comments
    for (const metaField of visibleMetaFields) {
        if (shouldRender(metaField)) {
            visibleComments.push(metaField.answer);
        }

        for (const childMetaField of metaField.children.filter(cmf => cmf.visible)) {
            if (shouldRender(childMetaField)) {
                visibleComments.push(childMetaField.answer);
            }
        }
    }

    return visibleComments;
}

class SubmissionFieldCommentModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            field_answers: [],
            form_fields: [],
        };

    }

    componentDidMount() {
        get_submission(this.props.submission_uuid)
        .then(response => response.json())
        .then(success => {
            const submissionFields = safe_get(success, "submission.fields", []);
            const formFields = safe_get(success, "submission.form.fields", []);

            
            const sortedSubmissionFields = submissionFields.sort((a, b) => {
                // Need to get form field instead of answer field if possible.
                // Form field has sectionId, answer field does not.
                const aSearchField = formFields.find(field => field.id === a.id) || a;
                const bSearchField = formFields.find(field => field.id === b.id) || b;

                const aId = aSearchField.id;
                const aSectionId = aSearchField.sectionId;

                const bId = bSearchField.id;
                const bSectionId = bSearchField.sectionId;

                // Choose which IDs we want to use for sorting. If field has section, use the section ID, unless both fields
                // are from the same section. In that case, we use the actual field ID. If there isn't a section ID, also use
                // field ID.
                let aSortId, bSortId;
                if (aSectionId && aSectionId === bSectionId) {
                    aSortId = aId;
                    bSortId = bId;
                } else {
                    if (aSectionId) aSortId = aSectionId;
                    else aSortId = aId;

                    if (bSectionId) bSortId = bSectionId;
                    else bSortId = bId;
                }

                // Sort based on index from form fields.
                const aIndex = formFields.findIndex(field => field.id === aSortId);
                const bIndex = formFields.findIndex(field => field.id === bSortId);

                return aIndex < bIndex ? -1 : 1;
            });

            const visibleCommentFields = getVisibleCommentFields(formFields, sortedSubmissionFields, this.props.mode ?? 'comments');
            const fieldAnswerIds = visibleCommentFields.map(field => field.id);

            // we sort form fields using same indices from our previous sorted answer fields because that's how FormFieldsRenderer works
            const sortedFormFields = (
                formFields
                .filter(field => fieldAnswerIds.includes(field.id))
                .sort((a, b) => sortedSubmissionFields.findIndex(field => field.id === a.id) < sortedSubmissionFields.findIndex(field => field.id === b.id) ? -1 : 1)
                .map((field) => ({...field, sectionId: undefined, custom_logic: undefined})) // remove sectionId && custom_logic (already handled in getVisibleCommentFields)
            );

            this.setState({
                submission: success.submission,
                form_fields: sortedFormFields,
                field_answers: sortedSubmissionFields,
                loading: false
            });

        })
        .catch(error => {
            this.setState({loading: false});
        });
    }

    linkPostToField = (post_uuid, field_id) => {
        if (this.props.submission_uuid) {
            link_post_to_field(this.props.submission_uuid, JSON.stringify({post_uuid, field_id})).catch(() => {
                NotificationAlert("error", "", "Unable to link post to comment.");
            })
        }
    }

    render() {
        return (
            (<Modal
                title={this.props.mode === "attachments" ? "Files" : "Comments"}
                open={this.props.show}
                maskClosable={false}
                onCancel={this.props.cancel}
                maskTransitionName="maskTransitionName"
                footer={
                    <div style={{height: "33px"}}>
                        <button className="btn btn-modal" onClick={this.props.cancel}>
                            Done
                        </button>
                    </div>
                }
            >
                <div style={{maxHeight: "450px", overflow: "auto"}}>
                    {
                        this.state.loading &&
                        <LoadingIndicator/>
                    }
                    {
                        !this.state.loading &&
                        <FormFieldsRenderer
                            form_uuid={safe_get(this.state, "submission.form.form_uuid", "")}
                            submission_uuid={safe_get(this.state, "submission.submission_uuid", "")}
                            form_fields={this.state.form_fields}
                            field_answers={this.state.field_answers}
                            pages={[this.state.form_fields]}
                            page={0}
                            saveDraft={() => {
                            }}
                            isAnalytics={true}
                            isComment={true}
                            linkPostToField={this.linkPostToField}
                        />
                    }
                    {
                        !this.state.loading && this.state.form_fields.length === 0 &&
                        <p>No comments.</p>
                    }
                </div>
            </Modal>)
        );
    }

}

export default SubmissionFieldCommentModal;