// @ts-check
import { useHistory } from "react-router-dom";
import { useSubmissionDataGridContext } from "./SubmissionDataGrid.context";
import { EventNames, getSubmissionLink } from "./SubmissionDataGrid.utils";
import useOrganizationId from "hooks/useOrganizationId";
import ConfirmModal from "components/Shared/ConfirmModal";
import { delete_submission } from "api/zero-api";
import NotificationAlert from "other/NotificationAlert";
import ExportSubmissionModal from "../ExportSubmissionModal";
import { useZeroContext } from "components/ZeroContext";
import SubmissionFieldCommentModal from "../SubmissionFieldCommentModal";

export function SubmissionDataGridModals() {
    const history = useHistory();
    const { events } = useZeroContext();
    const {activeModal, setActiveModal, gridRef} = useSubmissionDataGridContext();
    const orgId = useOrganizationId();

    if (!activeModal) {
        return null;
    }

    const {key, submission} = activeModal;
    const closeModal = () => setActiveModal(null);
    const refreshDataGrid = () => events.emit(EventNames.REFRESH_DATA);
    const handleEditClicked = () => {
        history.replace(history.location.pathname, {gridState: gridRef.current.api.getState()});
        history.push(getSubmissionLink(submission, orgId, {forEdit: true}));
    }

    const sharedProps = {
        submission,
        closeModal,
        refreshDataGrid
    }

    switch (key) {
        case "delete_submission":
            return <DeleteModal {...sharedProps} />;
        case "edit_submission":
            handleEditClicked();
            break;
        case "export_submission":
            return (
                <ExportSubmissionModal
                    show={true}
                    submission_uuid={submission.submission_uuid}
                    cancel={closeModal}
                />
            );
        case "comments":
            return (
                <SubmissionFieldCommentModal
                    show
                    cancel={closeModal}
                    submission_uuid={submission.submission_uuid}
                    mode="comments"
                />
            )
        case "attachments":
            return (
                <SubmissionFieldCommentModal
                    show
                    cancel={closeModal}
                    submission_uuid={submission.submission_uuid}
                    mode="attachments"
                />
            )
        default:
            console.error(`Unknown submission table modal '${key}'`);
    }

    return null;
}

function DeleteModal({submission, closeModal, refreshDataGrid}) {
    const deleteSubmission = async () => {
        try {
            await delete_submission(submission.submission_uuid);
            NotificationAlert("success", "", "Submission deleted.");
            refreshDataGrid();
        } catch (err) {
            NotificationAlert("error", "", "Unable to delete submission.");
        } finally {
            closeModal();
        }
    }

    return (
        <ConfirmModal
            show={true}
            cancel={closeModal}
            confirm={deleteSubmission}
            title={"Confirmation"}
            body={"Are you sure you want to delete this submission? This action cannot be undone."}
            confirmButtonName={"Delete"}
        />
    )
}