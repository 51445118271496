import React, {Component} from 'react';
import {connect} from 'react-redux'
import {withRouter} from 'react-router'

import safe_get from '../../other/SafeGet.js'
import {isAdmin, isTeamLead, isViewer} from '../../other/Helper.js';

import zero_support_pic from '../../assets/css/img/Life_Preserver.jpg'
import default_post_img from '../../assets/css/img/default_post_img.png'

class BulletinPost extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            contentHeight: 0,
            contentHeightMobile: 0,
            uuid: "",
            showMap: false,
            preview_visible: false,
            files: []
        };

        this.handleExploreClick = this.handleExploreClick.bind(this);
    }

    componentDidMount() {
        const height = this.refs.postContent.clientHeight;

        this.setState({contentHeight: height, contentHeightMobile: 0, uuid: this.props.uuid})

    }

    dateFormatter(date) {
        var revisedDate = new Date(date * 1000);
        var revised = revisedDate.toDateString();
        var formatDate = revised.split(" ");
        // var time = revisedDate.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})

        revised = formatDate[1] + " " + formatDate[2] + ", " + formatDate[3] //+ " at " + time;

        return revised;
    }

    dateFormatter2() {
        var revisedDate = new Date();
        if (['my_teams', 'organization_teams'].includes(this.props.current_team.uuid) === false) {
            revisedDate = new Date(this.props.current_team.created_at * 1000)
        } else {
            revisedDate = new Date(this.props.user.created_at * 1000)

        }
        var revised = revisedDate.toDateString();
        var formatDate = revised.split(" ");
        var time = revisedDate.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})

        revised = formatDate[1] + " " + formatDate[2] + ", " + formatDate[3] + " at " + time;

        return revised;
    }

    handleExploreClick() {
        if (isAdmin(this.props.user)) {
            window.location = `https://app.teamzero.com/${this.props.org_uuid}/home/team/${this.props.current_team.uuid}/?product_tour_id=24517`;
        } else if (isTeamLead(this.props.user)) {
            window.location = `https://app.teamzero.com/${this.props.org_uuid}/home/team/${this.props.current_team.uuid}/?product_tour_id=29919`;
        } else if (isViewer(this.props.user)) {
            window.location = `https://app.teamzero.com/${this.props.org_uuid}/home/team/${this.props.current_team.uuid}/?product_tour_id=29923`;
        } else {
            window.location = `https://app.teamzero.com/${this.props.org_uuid}/home/team/${this.props.current_team.uuid}/?product_tour_id=29922`;
        }
    }

    render() {
        var titleStyle = {
            color: '#1d2a35',
            fontSize: "17px",
            fontWeight: '600',
            lineHeight: '1.4',
            marginBottom: '0px',
            marginTop: "0px",
            wordBreak: 'break-word'
        }

        var contentStyle = {display: 'inline-block', width: '100%', float: 'left',}

        var isMyPosts = this.props.location.pathname.includes("my_posts");

        return (
            <div>
                <div className="panel article thin-border animated fadeIn">

                    <div className="panel-body pad-no bulletin-post">
                        <div ref="postContent" style={contentStyle}>
                            <h3 style={titleStyle}>
                                Welcome to ZERO <span role="img">👋</span>
                            </h3>
                            <p style={{
                                wordWrap: 'break-word',
                                whiteSpace: 'pre-line',
                                color: "#505050",
                                marginBottom: "0px"
                            }}>

                                {
                                    ['my_teams', 'organization_teams'].includes(this.props.current_team.uuid)
                                        ? <span>ZERO is a place where everyone on your team can observe, report, and resolve issues in your workplace. From observations and issue resolution to audits, inspections, training, and incident reporting, it can all be done seamlessly in ZERO. If you have any questions, feel free to contact <a
                                            className="blue-link"
                                            href={"mailto:support@teamzero.com"}>support@teamzero.com</a>. Learn more about ZERO <a
                                            rel="noopener noreferrer"
                                            onClick={() => FreshworksWidget('open', 'article', {'id': 70000417523})}
                                            className="blue-link">here</a>. Welcome!</span>
                                        :
                                        <span>{safe_get(this.props, "current_team.created_by.first_name", "")} {safe_get(this.props, "current_team.created_by.last_name", "")} created this team on {this.dateFormatter(this.props.current_team.created_at)}, and this is the very first post in this team. ZERO is a place where everyone on your team can observe, report, and resolve issues in your workplace. From observations and issue resolution to audits, inspections, training, and incident reporting, it can all be done seamlessly in ZERO. If you have any questions, feel free to contact <a
                                            className="blue-link"
                                            href={"mailto:support@teamzero.com"}>support@teamzero.com</a>. Learn more about ZERO <a
                                            rel="noopener noreferrer"
                                            onClick={() => FreshworksWidget('open', 'article', {'id': 70000417523})}
                                            className="blue-link">here</a>. Welcome!</span>
                                }

                            </p>
                            <br/>
                            <img src={default_post_img} alt="default_post_image"
                                 style={{display: "block", margin: "auto", width: "80%"}}/>
                            <br/>
                        </div>
                    </div>
                    <hr className="bulletin"/>
                    <div className="panel-heading update">
                        <div className="media-left">
                            <img className="img-circle img-sm bulletin" alt="Profile Pic" src={zero_support_pic}/>
                        </div>
                        <div className="media-body bulletin">
                            <h3 className="author bulletin truncate name long">ZERO Support</h3>
                            <br/>
                            <h3 className="author bulletin date">{this.dateFormatter2()}</h3>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}


// export default BulletinPost;

const mapStateToProps = store => {
    return {
        current_team: safe_get(store, "teams_helper.team", {}),
        org_uuid: safe_get(store, "org_helper.organization.organization_uuid", {}),
        user: safe_get(store, "user.user", {}),
    }
}

export default withRouter(connect(mapStateToProps)(BulletinPost));




