// @ts-check

import { IoIosMore as MoreIcon } from 'react-icons/io';
import { ArrowRightOutlined, CloudDownloadOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Link, useHistory } from "react-router-dom";
import UserPopoverCard from "components/Shared/UserPopoverCard";
import IncidentStatusTag from "../IncidentStatusTag";
import { isAdmin, isAdminOrTeamLead, isTeamLead, safeProfilePic } from "other/Helper";
import { useCurrentUser } from "hooks/reduxHooks";
import useOrganizationId from "hooks/useOrganizationId";
import useZeroSelector from "hooks/useZeroSelector";
import { Dropdown } from "antd";
import { useMemo } from 'react';
import { useIncidentDataGridContext } from './IncidentDataGrid.context';
import { isNumber } from 'lodash-es';
import ButtonLink from 'components/Shared/ButtonLink';

/**
 * @param {AgGridReactLib.CustomCellRendererProps} props
 */
export function IncidentActionsCell({data: incident}) {
    const user = useCurrentUser();
    const { setActiveModal } = useIncidentDataGridContext();

    if (!incident) {
        return null;
    }

    const isDisabled = isAdminOrTeamLead(user) === false;
    const isAuthor = incident.created_by.uuid === user.uuid;
    const deleteIsDisabled = !(isAdmin(user) || (isTeamLead(user) && isAuthor));

    const handleMenuItemClicked = ({key}) => {
        setActiveModal({
            key,
            incident,
        });
    }

    const tableMenu = useMemo(() => {
        return {
            onClick: handleMenuItemClicked,
            items: [
                {
                    key: "edit",
                    disabled: isDisabled,
                    icon: <EditOutlined/>,
                    label: "Edit"
                },
                {
                    key: "export",
                    disabled: isDisabled,
                    icon: <CloudDownloadOutlined/>,
                    label: "Export"
                },
                {
                    key: "move",
                    disabled: isDisabled,
                    icon: <ArrowRightOutlined/>,
                    label: "Move"
                },
                {
                    type: "divider"
                },
                {
                    key: "delete",
                    disabled: deleteIsDisabled,
                    className: deleteIsDisabled ? "" : "zero-delete-red",
                    icon: <DeleteOutlined/>,
                    label: "Delete"
                }
            ],
        }
    }, [incident, user, isDisabled, isAuthor, deleteIsDisabled]);

    return (
        <div className="more pull-right" style={{display: "inline-block", color: "grey", lineHeight: 0}}>
            {/* @ts-ignore */}
            <Dropdown menu={tableMenu} trigger={['click']} placement="bottomRight">
                <button className="ButtonLink ant-dropdown-link">
                    <MoreIcon style={{height: '20px', width: '20px'}}/>
                </button>
            </Dropdown>
        </div>
    );
}

/**
 * @param {AgGridReactLib.CustomCellRendererProps} props
 */
export function IncidentCaseNumberCell({data: incident, api}) {
    const history = useHistory();
    const user = useCurrentUser();
    const orgId = useOrganizationId();
    const currentLocation = useZeroSelector(state => state.incidents.location)

    if (!incident) {
        return null;
    }

    if (!isAdminOrTeamLead(user)) {
        return incident.case_number;
    }

    const linkUrl = `/${orgId}/home/incidents/location/${currentLocation.location_uuid}/edit_incident/${incident.incident_uuid}/1`;

    const onClick = (event) => {
        event.preventDefault();
        history.replace(history.location.pathname, {gridState: api.getState()});
        history.push(linkUrl);
    }

    return <Link to={linkUrl} className="blue-link" onClick={onClick}>{incident.case_number}</Link>;
}

/**
 * @param {AgGridReactLib.CustomCellRendererProps} props
 */
export function IncidentStatusCell({data: incident}) {
    if (!incident) {
        return null;
    }

    return <IncidentStatusTag incident={incident} />
}

/**
 * @param {AgGridReactLib.CustomCellRendererProps} props
 */
export function IncidentCreatedByCell({value: user}) {
    if (!user) {
        return null;
    }

    return (
        <UserPopoverCard user={user}>
            <span style={{ cursor: "pointer" }}>
                {safeProfilePic(user, "img-sm img-circle bulletin", "bulletin", {
                    marginRight: "5px",
                    display: "inline-block",
                })}
            </span>
        </UserPopoverCard>
    );
}

/**
 * @param {AgGridReactLib.CustomCellRendererProps} props
 */
export function IncidentCorrectiveActionsCell({value: count, data: incident}) {
    const { setActiveModal } = useIncidentDataGridContext();

    if (!isNumber(count)) {
        return null;
    }

    if (count === 0) {
        return "No";
    }

    const showCorrectiveActionsModal = () => {
        setActiveModal({
            key: "corrective-actions",
            incident,
        })
    }

    return (
        <ButtonLink onClick={showCorrectiveActionsModal}>Yes ({count})</ButtonLink>
    );
}


