// @ts-check
import ZeroDataGrid from "components/Shared/ZeroDataGrid";
import { useIncidentDataGridContext } from "./IncidentDataGrid.context";
import { EventNames, getColumnDefs, loadIncidents } from "./IncidentDataGrid.utils";
import { useCallback, useEffect, useMemo } from "react";
import useZeroSelector from "hooks/useZeroSelector";
import { objectIsEmpty } from "other/Helper";
import { useLocation } from "react-router-dom";
import { isEqual } from "lodash-es";
import { useZeroEventListener } from "components/ZeroContext";

const INCIDENTS_PER_PAGE = 25;

export default function IncidentDataGrid() {
    const location = useLocation();
    const {gridRef, settings, setSettings, rowCount, incidentOptions, customFields} = useIncidentDataGridContext();
    const filterQuery = useZeroSelector(state => state.incidents.filterQuery);
    const periodQuery = useZeroSelector(state => state.incidents.periodQuery);

    const colDefs = useMemo(() => getColumnDefs(incidentOptions, customFields), [incidentOptions, customFields]);

    const getRows = useCallback(async (/** @type {AgGrid.IGetRowsParams} */params) => {
        const setLoading = (isLoading) => {
            if (rowCount === null) {
                gridRef.current?.api?.setGridOption("loading", isLoading);
            }
        }

        const currentPage = Math.floor((params.startRow ?? 0) / INCIDENTS_PER_PAGE) + 1;

        const queryParams = new URLSearchParams(filterQuery + periodQuery);
        queryParams.set('per_page', String(INCIDENTS_PER_PAGE));
        queryParams.set('page', String(currentPage));

        if (!objectIsEmpty(params.filterModel)) {
            queryParams.set('ag_filter', JSON.stringify(params.filterModel));
        }

        params.sortModel.forEach(sortModel => {
            let col = sortModel.colId;
            if (sortModel.sort === 'desc') {
                col = `-${col}`;
            }
            queryParams.append('sort_by', col);
        });

        setLoading(true);
        const {incidents, totalIncidents} = await loadIncidents(queryParams);
        params.successCallback(incidents, totalIncidents);
        setLoading(false);
    }, [rowCount, filterQuery, periodQuery]);

    const onGridReady = useCallback((/** @type {AgGrid.GridReadyEvent} */ event) => {
        /** @type {AgGrid.IDatasource} */
        const dataSource = {
            rowCount: undefined,
            getRows,
        };
        event.api.setGridOption("datasource", dataSource);
    }, [getRows]);

    useEffect(() => {
        const agApi = gridRef.current?.api;
        if (agApi) {
            const datasource = {
                rowCount: undefined,
                getRows,
            };
            agApi.setGridOption("datasource", datasource);
        }
    }, [gridRef, getRows]);

    const onStateUpdated = useCallback((/** @type {AgGrid.StateUpdatedEvent} */ event) => {
        if (event.sources.includes("columnOrder")) {
            // @ts-ignore
            const columnDefsOrder = colDefs.map(def => def.field);
            const newColumnOrder = event.state.columnOrder.orderedColIds;
            const isDefaultOrder = isEqual(columnDefsOrder, newColumnOrder);
            if (isDefaultOrder) {
                setSettings('columnOrder', null);
            } else {
                setSettings('columnOrder', event.state.columnOrder);
            }
        }
        
        if (event.sources.includes("sort")) {
            setSettings('sort', event.state.sort);
        }
    }, [setSettings]);

    const resetTable = useCallback(() => {
        const grid = gridRef.current;
        if (!grid) {
            return;
        }
        setSettings('columnOrder', null);
        grid.api.resetColumnState();
    }, [setSettings]);

    const refreshData = useCallback(() => {
        const grid = gridRef.current;
        if (!grid) {
            return;
        }
        grid.api.refreshInfiniteCache();
    }, []);

    useZeroEventListener(EventNames.RESET_TABLE, resetTable);
    useZeroEventListener(EventNames.REFRESH_DATA, refreshData);

    return (
        <ZeroDataGrid
            gridRef={gridRef}
            columnDefs={colDefs}
            defaultColDef={{
                cellStyle: {
                    display: 'flex',
                    alignItems: 'center',
                }
            }}
            initialState={location.state?.gridState ?? settings}
            onGridReady={onGridReady}
            onStateUpdated={onStateUpdated}
            cacheBlockSize={INCIDENTS_PER_PAGE}
            cacheOverflowSize={2}
            maxConcurrentDatasourceRequests={2}
            infiniteInitialRowCount={rowCount ?? INCIDENTS_PER_PAGE}
            pagination={true}
            paginationPageSizeSelector={false}
            paginationPageSize={rowCount ?? INCIDENTS_PER_PAGE}
            tooltipShowDelay={500}
            tooltipShowMode="standard"
            suppressCellFocus
        />
    );
}
