// @ts-check

import { useHistory } from "react-router-dom";
import useOrganizationId from "hooks/useOrganizationId";
import { useIncidentDataGridContext } from "./IncidentDataGrid.context";
import { useZeroContext } from "components/ZeroContext";
import { EventNames } from "./IncidentDataGrid.utils";
import useZeroSelector from "hooks/useZeroSelector";
import DeleteIncidentModal from "../DeleteIncidentModal";
import MoveIncidentModal from "../MoveIncidentModal";
import { isAdmin } from "other/Helper";
import { useCurrentUser } from "hooks/reduxHooks";
import { move_incident } from "api/zero-api";
import NotificationAlert from "other/NotificationAlert";
import ExportIncidentModal from "../ExportIncidentModal";
import CorrectiveActionsModal from "../CorrectiveActionsModal";

export function IncidentDataGridModals() {
    const history = useHistory();
    const { events } = useZeroContext();
    const { activeModal, setActiveModal, gridRef } = useIncidentDataGridContext();
    const orgId = useOrganizationId();
    const user = useCurrentUser();
    const currentLocation = useZeroSelector(state => state.incidents.location);
    const locations = useZeroSelector(state => state.incidents.locations);

    if (!activeModal) {
        return null;
    }

    const {key, incident} = activeModal;
    const closeModal = () => setActiveModal(null);
    const refreshDataGrid = () => events.emit(EventNames.REFRESH_DATA);
    const handleEditClicked = () => {
        history.replace(history.location.pathname, {gridState: gridRef.current.api.getState()});
        history.push(`/${orgId}/home/incidents/location/${currentLocation.location_uuid}/edit_incident/${incident.incident_uuid}/1`);
    }
    const moveIncident = async (location_uuid) => {
        try {
            const body = JSON.stringify({
                location_uuid
            });

            const res = await move_incident(incident.incident_uuid, body);
            refreshDataGrid();
            closeModal();
            NotificationAlert("success", "", "Incident moved.");
        } catch (err) {
            closeModal();
            NotificationAlert("error", "", "Unable to move incident.");
        }
    }
    const goToPost = (postId) => {
        history.replace(history.location.pathname, {gridState: gridRef.current.api.getState()});
        history.push(`/${orgId}/home/team/my_teams/feed/post/${postId}`);
    }

    switch (key) {
        case "edit":
            handleEditClicked();
            break;
        case "export":
            return (
                <ExportIncidentModal
                    show
                    onModalClosed={closeModal}
                    incident={incident}
                />
            );
        case "move":
            return (
                <MoveIncidentModal
                    show
                    cancel={closeModal}
                    locations={locations}
                    source_location={incident.location_uuid}
                    confirm={moveIncident}
                    canMove={isAdmin(user)}
                />
            );
        case "delete":
            return (
                <DeleteIncidentModal
                    show
                    incident={incident}
                    cancel={closeModal}
                    successCallback={refreshDataGrid}
                />
            );
        case "corrective-actions":
            return (
                <CorrectiveActionsModal
                    show
                    cancel={closeModal}
                    incident_uuid={incident.incident_uuid}
                    goToPost={goToPost}
                />
            );
        default:
            console.error(`Unknown incident table modal '${key}'`);
    }

    return null;
}