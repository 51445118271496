// @ts-check

/**
 * 
 * @param {{
 *  icon: any,
 *  className?: string,
 *  size?: string,
 * }} props
 * @returns 
 */
export default function RoundIconButton({icon: Icon, className: propsClassName = "", size, ...props}) {
    const className = `round-icon-button ${propsClassName}`.trim();
    
    const style = {};
    if (size) {
        style['--round-icon-btn-size'] = size;
    }

    return (
        <button
            className={className}
            // @ts-ignore
            style={style}
            {...props}
        >
            <Icon />
        </button>
    )
}