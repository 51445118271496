// @ts-check
import { ModuleRegistry } from '@ag-grid-community/core';
import { InfiniteRowModelModule } from '@ag-grid-community/infinite-row-model'
import { ExcelExportModule } from "@ag-grid-enterprise/excel-export";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import {LicenseManager} from '@ag-grid-enterprise/core'

ModuleRegistry.registerModules([
    InfiniteRowModelModule,
    ExcelExportModule,
    SetFilterModule,
]);

const agGridLicenseKey = 'Using_this_{AG_Grid}_Enterprise_key_{AG-064236}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Salus_Systems,_LLC}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{ZERO}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{ZERO}_need_to_be_licensed___{ZERO}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{26_July_2025}____[v3]_[01]_MTc1MzQ4NDQwMDAwMA==2ef6fc9f63c9dc9dc3714f968597b1f8';
LicenseManager.setLicenseKey(agGridLicenseKey);