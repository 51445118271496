// @ts-check

import { useZeroContext } from "components/ZeroContext";
import { isEqual } from "lodash-es";
import { useMemo } from "react";

export default function ResetGridLink({settings, resetTableEventName, defaultSortModel}) {
    const { events } = useZeroContext();

    const reset = () => {
        events.emit(resetTableEventName);
    };

    const hasDefaultSettings = useMemo(() => {
        if (!settings) {
            return true;
        }

        if (settings.columnOrder) {
            return false;
        }

        if (!settings.sort && defaultSortModel !== null) {
            return false;
        }

        if (settings.sort) {
            if (defaultSortModel === null) {
                return false;
            }
            const sortModel = settings.sort.sortModel;
            if (!isEqual(sortModel, defaultSortModel)) {
                return false;
            }
        }

        return true;
    }, [settings]);

    if (!hasDefaultSettings) {
        return (
            <span className="blue-link" onClick={reset}>
                Reset table
            </span>
        );
    }

    return null;
}