// @ts-check

import { get_incidents } from "api/zero-api";
import { AgGridFilter } from "other/agGridHelper"
import { dateFormatterFromString, formatName } from "other/Helper";
import { IncidentActionsCell, IncidentCaseNumberCell, IncidentCorrectiveActionsCell, IncidentCreatedByCell, IncidentStatusCell } from "./IncidentDataGrid.cells";
import { isNumber, startCase } from "lodash-es";

export const EventNames = {
    // Emitted when "Reset table" is clicked. Resets sorting and column order.
    RESET_TABLE: 'IncidentDataGrid:resetTable',
    // Emitted when table should refetch datasource
    REFRESH_DATA: 'IncidentDataGrid:refreshData',
}

/**
 * @param {import("./IncidentDataGrid.context").IncidentOptions} incidentOptions
 * @param {any[]} customFields
 * @returns {AgGrid.GridOptions['columnDefs']}
 */
export function getColumnDefs(incidentOptions, customFields) {
    /** @type {AgGrid.GridOptions['columnDefs']} */
    const defs = [
        {
            headerName: "",
            field: "_actions_",
            width: 50,
            resizable: false,
            sortable: false,
            suppressMovable: true,
            lockPosition: "left",
            cellRenderer: IncidentActionsCell,
            cellStyle: {
                padding: 0,
            },
        },
        {
            headerName: "Case #",
            field: "case_number",
            cellRenderer: IncidentCaseNumberCell,
            ...AgGridFilter.textNoBlankOptions,
        },
        {
            headerName: "Location",
            field: "location_name",
            ...AgGridFilter.textNoBlankOptions,
        },
        {
            headerName: "Logged By",
            field: "created_by",
            width: 150,
            valueFormatter: ({value: user}) => formatName(user, {defaultName: ""}),
            cellRenderer: IncidentCreatedByCell,
            ...AgGridFilter.textNoBlankOptions,
        },
        {
            headerName: "Status",
            field: "status",
            valueFormatter: ({value: status}) => status ? startCase(status) : '',
            cellRenderer: IncidentStatusCell,
            ...AgGridFilter.setFilter({open: 'Open', in_review: 'In Review', closed: 'Closed'}, '', '', {addBlank: false}),
        },
        {
            headerName: "Date of Event",
            field: "date_of_injury",
            valueFormatter: ({value}) => dateFormatterFromString(value),
            ...AgGridFilter.date,
        },
        {
            headerName: "Employee",
            field: "full_name",
            ...AgGridFilter.textNoBlankOptions,
        },
        {
            headerName: "Case Type",
            field: "incident_type",
            valueFormatter: ({value}) => getCaseTypeName(value, incidentOptions.incident_types),
            ...AgGridFilter.setFilter(incidentOptions.incident_types, 'value', 'name'),
        },
        {
            headerName: "Incident Type",
            field: "_incident_type_",
            sortable: false,
            valueFormatter: ({data}) => getIncidentType(data),
            ...AgGridFilter.setFilter({incident_injury: "Injury", incident_illness: "Illness"}),
        },
        {
            headerName: "Sub-Type",
            field: "_injury_illness_type_",
            sortable: false,
            valueFormatter: ({data}) => getInjuryOrIllnessType(data, incidentOptions),
            ...AgGridFilter.setFilter(
                [
                    ...incidentOptions.incident_illnesses,
                    ...incidentOptions.incident_injuries.map(inj => {
                        inj['value'] = inj['incident_injury_uuid'];
                        return inj;
                    })
                ],
                'value',
                'name'
            ),
        },
        {
            headerName: "Incident Cause",
            field: "incident_cause",
            valueFormatter: ({value}) => value?.name ?? "",
            ...AgGridFilter.setFilter(incidentOptions.incident_causes, "incident_cause_uuid", "name"),
        },
        {
            headerName: "Event Type",
            field: "event_type",
            valueFormatter: ({value: eventType}) => eventType ? eventType.name : "",
            ...AgGridFilter.setFilter(incidentOptions.event_types, "event_type_uuid", "name"),
        },
    ];

    for (const cf of customFields) {
        if (!cf.enabled) {
            continue;
        }

        const field = cf.type.replace('incident_', '');

        defs.push({
            headerName: cf.name || "Custom Field",
            field,
            valueFormatter: ({value}) => value?.label ?? "",
            ...AgGridFilter.setFilter(cf.options, "uuid", "label"),
        })
    }

    defs.push({
        headerName: "Actions + Notes",
        field: "corrective_actions_count",
        valueFormatter: ({value: count}) => count ? `Yes (${count})` : 'No',
        cellRenderer: IncidentCorrectiveActionsCell,
    });
    
    return defs;
}

export async function loadIncidents(queryParams) {
    const res = await get_incidents(`?${queryParams}`);
    const {incidents, total_incidents: totalIncidents} = await res.json();
    return {incidents, totalIncidents};
}

/**
 * 
 * @param {number | undefined} incidentType 
 * @param {{value: number, name: string}[]} incidentTypes 
 * @returns {string}
 */
function getCaseTypeName(incidentType, incidentTypes) {
    if (isNumber(incidentType)) {
        const match = incidentTypes.find(it => it.value === incidentType);
        if (match) {
            return match.name;
        }
    }

    return '';
}

function getIncidentType(incident) {
    return incident?.incident_illness ? "Illness" : incident?.incident_injury ? "Injury" : ""
}

/**
 * @param {any} incident 
 * @param {import("./IncidentDataGrid.context").IncidentOptions} incidentOptions 
 * @returns {string}
 */
function getInjuryOrIllnessType(incident, incidentOptions) {
    if (incident?.incident_illness) {
        const illness = incidentOptions.incident_illnesses.find((illness) => illness.value === incident.incident_illness);
        if (illness) {
            return illness.name;
        }
    } else if (incident?.incident_injury) {
        return incident.incident_injury.name;
    }
    return "";
}